import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { fetchCows } from '../../cows/actions';
import PropTypes from 'prop-types';
import Login from '../../user/components/Login';
import Logo from '../../header/components/Logo';
import Button from '../../util/buttons/components/Button';
import './home.scss';
import { isAuthenticated } from '../../util/jwt';

const mapDispatchToProps = dispatch => ({
  getCows: () => dispatch(fetchCows())
});

class LoginPage extends Component {
  render () {
    return (
      <div id="home-page">
        <Logo />
        { isAuthenticated()
          ? (
            <Link to={'/cattle/young-bulls'}>
              <Button label="enter" />
            </Link>
          ) : <Login history={ this.props.history } />
        }
      </div>
    );
  }
}

LoginPage.propTypes = {
  getCows: PropTypes.func.isRequired,
  history: PropTypes.object
};

export default connect(null, mapDispatchToProps)(LoginPage);
