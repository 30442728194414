import React, { Component } from 'react';
import { connect } from 'react-redux';
import { login } from '../actions';
import PropTypes from 'prop-types';
import Button from '../../util/buttons/components/Button';
import '../../util/buttons/components/button-row.scss';
import './login.scss';
import { currentlyLoggingIn } from '../reducer';
// import { Link } from 'react-router-dom';

const mapDispatchToProps = dispatch => ({
  login: (user, password, next, history) =>
    dispatch(login(user, password, next, history))
});

const mapStateToProps = state => ({
  loggingIn: currentlyLoggingIn(state)
});

class Login extends Component {
  constructor (props) {
    super(props);
    this.login = this.login.bind(this);
    this.userRef = React.createRef();
    this.passwordRef = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  login () {
    const user = this.userRef.current.value;
    const password = this.passwordRef.current.value;
    this.props.login(user, password, null, this.props.history);
  }

  handleClick (event) {
    // event.preventDefault();
    event.stopPropagation();
  }

  render () {
    return (
      <form className="bb-login" onClick={ this.handleClick }>
        <input ref={this.userRef} className="user" placeholder="Username" />
        <input
          ref={this.passwordRef}
          className="password"
          type="password"
          placeholder="password"/>
        <div className="bb-button-row right">
          {/* <Link className="gbi-only" to="/register">
            <Button label="register" />
          </Link> */}
          <Button label="Login" onClick={this.login} />
        </div>
      </form>
    );
  }
}

Login.propTypes = {
  login: PropTypes.func.isRequired,
  redirect: PropTypes.string,
  history: PropTypes.object,
  loggingIn: PropTypes.bool
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
